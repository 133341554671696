@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /* display: none; */
}

html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background-color: rgba(210, 210, 210, 1);
}
.signup-btn {
  display: inline-block;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.signup-btn::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFBF00;;
  z-index: -2;
}

.signup-btn::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color:  #d29f13 ;
  transition: all 0.3s;
  z-index: -1;
  color: black;
}

.signup-btn:hover {
  color: black;
}

.signup-btn:hover::before {
  width: 100%;
}
.drop-arrow-link {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.drop-arrow-link .drop-arrow-icon {
  position: relative;
  z-index: 1;
  transition: color 0.3s;
}

.drop-arrow-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #d69d00; /* Desired background color */
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 0;
}

.drop-arrow-link:hover .drop-arrow-icon {
  color: #fff; /* Change icon color on hover */
}

.drop-arrow-link:hover::before {
  transform: translateY(0);
}


@keyframes fade-slide {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-slide {
  animation: fade-slide 0.6s ease-in-out;
}


.image-container {
  overflow: hidden;
}

/* Existing code */
.image-zoom {
  transition: transform 0.3s ease; 
}

.image-zoom:hover {
  transform: scale(1.1); 
}

@keyframes draw-line {
  from {
      width: 0;
  }
  to {
      width: 13rem; 
  }
}

.line-animation {
  animation: draw-line 1s ease forwards;
  border-top-width: 8px; 
  border-color: #FFBF00; 
  opacity: 1; 
  width: 13rem; 
}
